/* eslint-disable max-lines */
import { CartFragment, CartUserErrorFragment, CustomerAddressFragment } from "./fragments";

export const customerQuery = `
	${CustomerAddressFragment}
	query getCustomer {
		customer {
			displayName
			firstName
			lastName
			id
			creationDate
			metafields(identifiers: [{namespace: "custom", key: "used_discount_codes"}, {namespace: "custom", key: "applied_discounts"}]) {
				value 
				key
			} 
			emailAddress {
				emailAddress
			}
			defaultAddress {
				...CustomerAddressFragment
			}
		}
	}
`;

export const personalAddressesQuery = (afterCursorEl) => `
	${CustomerAddressFragment}
	query getPersonalAddresses {
		customer {
			addresses(first: 8, ${afterCursorEl}, reverse: true) {
				pageInfo {
					hasPreviousPage
					hasNextPage
					endCursor
				}
				nodes {
					...CustomerAddressFragment
				}
			}
			defaultAddress {
				...CustomerAddressFragment
			}
		}
	}
`;

export const customerAddressUpdateMutation = `
	${CustomerAddressFragment}
	mutation customerAddressUpdate($addressId: ID!, $address: CustomerAddressInput!, $defaultAddress: Boolean) {
		customerAddressUpdate(addressId: $addressId, address: $address, defaultAddress: $defaultAddress) {
			customerAddress {
				...CustomerAddressFragment
			}
			userErrors {
				field
				message
			}
		}
	}
`;

export const customerAddressCreateMutation = `
	${CustomerAddressFragment}
	mutation customerAddressCreate($address: CustomerAddressInput!, $defaultAddress: Boolean) {
		customerAddressCreate(address: $address, defaultAddress: $defaultAddress) {
			customerAddress {
				...CustomerAddressFragment
			}
			userErrors {
				field
				message
			}
		}
	}
`;

export const customerAddressDeleteMutation = `mutation customerAddressDelete($addressId: ID!) {
  customerAddressDelete(addressId: $addressId) {
    userErrors {
      field
    }
    deletedAddressId
  }
}`;

export const changeDefaultAddressMutation = `
	${CustomerAddressFragment}
	mutation customerAddressUpdate($addressId: ID!, $defaultAddress: Boolean!) {
		customerAddressUpdate(addressId: $addressId, defaultAddress: $defaultAddress) {
			customerAddress {
				...CustomerAddressFragment
			}
			userErrors {
				field
			}
		}
	}
`;

export const ordersQuery = (afterCursorEl) => `
  query getOrders {
    customer {
      orders(first: 6, ${afterCursorEl}, sortKey: PROCESSED_AT, reverse: true) {
        pageInfo {
          endCursor
        }
        nodes {
          name
          statusPageUrl
        }
      }
    }
  }
`;

export const metafieldsSetMutation = `
	mutation metafieldsSet($metafields: [MetafieldsSetInput!]!) { 
		metafieldsSet(metafields: $metafields) { 
			metafields { 
				key 
				namespace 
				value 
			} 
			userErrors { 
				field 
				message 
			} 
		}
	}
`;


export const cartQuery = `
	${CartFragment}
	query ($id: ID!)  {
		cart(id: $id) {
				...CartFragment
		}
	}
`;

export const cartCreateMutation = `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartCreate($cartInput: CartInput!)  {
		cartCreate(input: $cartInput) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}`
	;

export const cartLinesAddMutation = `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartLinesUpdateMutation = `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
		cartLinesUpdate(cartId: $cartId, lines: $lines) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartLinesRemoveMutation = `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartDiscountCodesUpdateMutation = `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
		cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;

export const cartAttributesUpdateMutation = `
	${CartFragment}
	${CartUserErrorFragment}
	mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
		cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
			cart {
				...CartFragment
			}
			userErrors {
				...CartUserErrorFragment
			}
		}
	}
	`;