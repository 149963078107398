import wrapWithProvider from "./store";
import pageWrapper from "./wrapPageElement";
export const wrapRootElement = wrapWithProvider;
import "./src/styles/global.css";

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
	const isModal = location?.state?.modal;
	const preventUpdateScroll = location?.state?.preserveScroll;

	if (isModal || preventUpdateScroll)
		return false;

	return true;
};

export const wrapPageElement = pageWrapper;