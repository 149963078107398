export const CURRENT_COUNTRY = process.env.GATSBY_COUNTRY;
export const SHOPIFY_DOMAIN = process.env.GATSBY_SHOPIFY_DOMAIN;
export const ADMIN_API_TOKEN = process.env.GATSBY_ADMIN_API_TOKEN;
export const SANITY_PROJECT_ID = process.env.GATSBY_SANITY_PROJECT_ID;
export const SANITY_DATASET = process.env.GATSBY_SANITY_DATASET;
export const GA4_ID = process.env.GATSBY_GA4_ID;
export const FB_PIXEL_ID = process.env.GATSBY_FB_PIXEL_ID;
export const SITE_URL = process.env.GATSBY_SITE_URL;
export const CHECKOUT_DOMAIN = process.env.GATSBY_CHECKOUT_DOMAIN;
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const CURRENCY = process.env.GATSBY_CURRENCY;
export const INVOICE_APP_URL = process.env.GATSBY_INVOICE_APP_URL;
export const INVOICE_MULTIPLY_NR = process.env.GATSBY_INVOICE_MULTIPLY_NR;
export const SHOPIFY_STOREFRONT_TOKEN = process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN;
export const KLAVIYO_COMPANY_ID = process.env.GATSBY_KLAVIYO_COMPANY_ID;
export const KLAVIYO_B2B_ID = process.env.GATSBY_KLAVIYO_B2B_ID;
export const REVIEWS_STORE = process.env.GATSBY_REVIEWS_STORE;
export const CONVERT_ID = process.env.GATSBY_CONVERT_ID;
export const STORE_ID = process.env.GATSBY_STORE_ID;
export const CLIENT_ID = process.env.GATSBY_CLIENT_ID;

export const LOOP_ADMIN_BASE_URL = "https://api.loopsubscriptions.com/admin/2023-10";
export const LOOP_STOREFRONT_BASE_URL = "https://api.loopsubscriptions.com/storefront/2023-10";

export const REDIRECT_URL = process.env.GATSBY_CONTEXT === "deploy-preview" ?
	process.env.GATSBY_DEPLOY_PRIME_URL :
	process.env.GATSBY_LOGIN_REDIRECT_URL;

export const SHOPIFY_API_VERSION = "2024-10";
export const littledataPixelIds = { ga4MeasurementId: GA4_ID, fbPixelId: FB_PIXEL_ID, tiktokPixelId: "1648209194" };

export const MAIL_ID_REGEX = /CustomerAddress\/(\d+)/;

export const orderStatus = (translation) => ({
	AUTHORIZED: translation.order_status?.authorized,
	PENDING: translation.order_status?.pending,
	OVERDUE: translation.order_status?.overdue,
	EXPIRING: translation.order_status?.expiring,
	EXPIRED: translation.order_status?.expired,
	PAID: translation?.order_status?.paid,
	REFUNDED: translation.order_status?.refunded,
	PARTIALLY_REFUNDED: translation.order_status?.partially_refunded,
	PARTIALLY_PAID: translation.order_status?.partially_paid,
	VOIDED: translation.order_status?.voided,
	UNPAID: translation.order_status?.unpaid,
});

export const canceledReason = (translation) => ({
	CUSTOMER: translation.canceled_reason?.customer,
	DECLINED: translation.canceled_reason?.declined,
	FRAUD: translation.canceled_reason?.fraud,
	INVENTORY: translation.canceled_reason?.inventory,
	OTHER: translation.canceled_reason?.other,
});

export const CUSTOMER_STATE = {
	Logged_in: "LOGGED_IN",
	Logged_out: "LOGGED_OUT",
};

export const customerTokenKey = "every-custom-access-token";
export const customerSessionKey = "every-customer";
export const DOTS = "...";
export const loopTokenKey = "loopToken";
export const activeBoxKey = "mealBox";
export const frequencyKey = "frequency";
export const CART_ID = "cartId";
export const freeGiftRemovedKey = "freeGiftRemoved";
export const closedOfferBar = "closedOfferBar";
export const upsellsRemovedKey = "upsellsRemoved";
export const closedUpsells = "closedUpsells";

export const productSingleTranslations = {
	DE: {
		typicalValues: "durchschnittliche Nährwerte 	",
		energy: "Energiegehalt (kj/kcal)	",
		fat: "Fett (g)	",
		fattyAcids: "davon Gesättigte Fettsäuren (g)	",
		carbohydrates: "Kohlenhydrate (g)	",
		ofWhichSuggar: "davon Zucker (g)	",
		dietaryFiber: "Ballaststoffe (g)	",
		protein: "Eiweiß (g)	",
		salt: "Salz (g)	",
	},
	EN: {
		typicalValues: "Typical values",
		energy: "Energy (kj/kcal)	",
		fat: "Fat (g)	",
		fattyAcids: "of which saturated fatty acids  (g)",
		carbohydrates: "Carbohydrates  (g)",
		ofWhichSuggar: "of which sugar  (g)",
		dietaryFiber: "Dietary fiber  (g)",
		protein: "Protein (g)",
		salt: "Salt (g)",
	},
	FR: {
		typicalValues: "Valeurs nutritionnelles moyennes  ",
		energy: "Énergie (kj/kcal)	",
		fat: "Graisse (g)	",
		fattyAcids: "dont aciddes gras saturés  (g)	",
		carbohydrates: "Glucides  (g)	",
		ofWhichSuggar: "dont sucre  (g)	",
		dietaryFiber: "Fibres alimentaires  (g)	",
		protein: "Protéine (g)	",
		salt: "Sel (g)",
	},
	NL: {
		typicalValues: "Gemiddelde voedingswaarden",
		energy: "Energie (kj/kcal)	",
		fat: "Vet (g)		",
		fattyAcids: "hiervan verzadigde vetzuren  (g)	",
		carbohydrates: "Koolhydraten  (g)	",
		ofWhichSuggar: "van welke suikers  (g)	",
		dietaryFiber: "Vezel  (g)	",
		protein: "Eiwit (g)	",
		salt: "Zout (g)	",
	},
	DK: {
		typicalValues: "Gennemsnitlige Næringsværdier  	",
		energy: "Energi (kj/kcal)	",
		fat: "Fedt (g)	",
		fattyAcids: "heraf mættede fedtsyrer  (g)	",
		carbohydrates: "Kulhydrater  (g)	",
		ofWhichSuggar: "heraf sukker  (g)	",
		dietaryFiber: "Kostfibre  (g)	",
		protein: "Protéine (g)	",
		salt: "Sel (g)	",
	},
};

export const languages = ["DE", "EN", "FR", "NL", "DK"];

export const countries = [
	{ code: "CH", url: "https://www.everyfoods.ch", },
	{ code: "LI", url: "https://www.everyfoods.ch" },
	{ code: "DE", url: "https://www.every-foods.com" },
	{ code: "AT", url: "https://www.every-foods.com" },
	{ code: "DK", url: "https://www.every-foods.com" },
	{ code: "LU", url: "https://www.every-foods.nl" },
	{ code: "NL", url: "https://www.every-foods.nl" },
	{ code: "BE", url: "https://www.every-foods.nl" },
];

export const stayInCountry = "stayInCountry";

export const berlinZipCodes = [
	"10115", "10117", "10119", "10178", "10179", "10243", "10245", "10247", "10249", "10315", "10317", "10318", "10319", "10365",
	"10367", "10369", "10405", "10407", "10409", "10435", "10437", "10439", "10551", "10553", "10555", "10557", "10559", "10585",
	"10587", "10589", "10623", "10625", "10627", "10629", "10707", "10709", "10711", "10713", "10715", "10717", "10719", "10777",
	"10779", "10781", "10783", "10785", "10787", "10789", "10823", "10825", "10827", "10829", "10961", "10963", "10965", "10967",
	"10969", "10997", "10999", "12043", "12045", "12047", "12049", "12051", "12053", "12055", "12057", "12059", "12099", "12101",
	"12103", "12107", "12157", "12159", "12161", "12163", "12165", "12167", "12169", "12203", "12205", "12207", "12209", "12247",
	"12249", "12277", "12305", "12307", "12309", "12347", "12349", "12351", "12353", "12355", "12359", "12435", "12437", "12439",
	"12459", "12487", "12489", "12524", "12526", "12527", "12529", "12555", "12557", "12559", "12587", "12589", "12619", "12621",
	"12623", "12679", "12681", "12683", "12685", "12687", "12689", "13053", "13055", "13057", "13086", "13088", "13089", "13125",
	"13127", "13156", "13158", "13187", "13189", "13347", "13349", "13351", "13353", "13355", "13357", "13359", "13403", "13405",
	"13407", "13435", "13437", "13465", "13467", "13469", "13503", "13505", "13507", "13509", "13581", "13585", "13587", "13591",
	"13595", "13597", "13627", "14050", "14052", "14055", "14057", "14059", "14089", "14109", "14129", "14163", "14165", "14167",
	"14169", "14193", "14195", "14197", "14199",
];

export const discountsForWeight10To16 = CURRENT_COUNTRY === "ch" ?
	["Amount_Off_2_CHF_Benefit", "Amount_Off_4_CHF_Benefit_Maxi", "Amount_Off_4_CHF_Benefit_Autumn_Bundle"] :
	["Amount_Off_1_EUR_Benefit", "Amount_Off_2_EUR_Benefit_Maxi", "Amount_Off_2_EUR_Benefit_Autumn_Bundle"];

export const discountsForWeight16To20 = CURRENT_COUNTRY === "ch" ?
	["Amount_Off_4_CHF_Benefit", "Amount_Off_8_CHF_Benefit_Maxi", "Amount_Off_8_CHF_Benefit_Autumn_Bundle"] :
	["Amount_Off_2_EUR_Benefit", "Amount_Off_4_EUR_Benefit_Maxi", "Amount_Off_4_EUR_Benefit__Autumn_Bundle"];

export const discountsForWeightAbove20 = CURRENT_COUNTRY === "ch" ?
	["Amount_Off_5_CHF_Benefit", "Amount_Off_10_CHF_Benefit_Maxi", "Amount_Off_10_CHF_Benefit_Autumn_Bundle"] :
	["Amount_Off_2.5_EUR_Benefit", "Amount_Off_5_EUR_Benefit_Maxi", "Amount_Off_5_EUR_Benefit__Autumn_Bundle"];